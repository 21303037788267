import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { GetColors, GetPosts } from "../../../Services/Posts";
import { FormControl, InputLabel, Select, MenuItem, ListItemText } from "@mui/material";
import { PostObj } from "../../../Models/Post";
import Pagination from "@mui/material/Pagination";

const ListPost: React.FunctionComponent<{}> = (props) => {

  const [loading, setLoading] = useState(true);
  const [listPosts, setListPosts] = useState<PostObj[]>();
  const [listColors, setListColors] = useState<any>();
  const [type, setType] = useState<any>("poster");
  const [theme, setTheme] = useState<any>("all");
  const [model, setModel] = useState<any>("all");
  const [change, setChange] = useState<Boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageValue, setPageValue] = useState<number>(1);
  const [dataLenght, setDataLenght] = useState<any>();
  const [isSpinner, setIsSpinner] = useState<boolean>(true);

  let navigate = useNavigate();

  useEffect(() => {
    GetColors().then((resp) => {
      setLoading(false)
      setListColors(resp)
    }).catch((error) => {
      console.log("error")
    })
    GetPosts({ type: type, theme: theme,model :model, page: 1, perpage: 12 }).then((data) => {
      setPage(Math.ceil(data.total / 12))
      setDataLenght(data.total)
      setIsSpinner(false)
      setLoading(false)
      setListPosts(data.posters)
    }).catch((error) => {
      console.log("error")
    })
  }, [type, theme, change]);

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    GetPosts({ type: type, theme: theme, model:model, page: value, perpage: 12 }).then((data) => {
      setPage(Math.ceil(data.total /12))
      setListPosts(data.posters)
    }).catch(err => {
      // setIsError(true)
      // setShowMessage("Error");
    })

    // setTimeout(() => {
    //   setIsError(false);
    // }, 3000);
  };

  const HandleChangeType = (event: any) => {
    setType(event.target.value);
    setChange(!change)
  }

  const HandleChangeModel = (event: any) => {
    setModel(event.target.value);
    setChange(!change)
  }

  const HandleChangeTheme = (event: any) => {
    setTheme(event.target.value);
    setChange(!change)
  }

  const GeneratePost = (obj: any) => {

    navigate(`/generate/generate-post`, { state: obj })
  }

  return loading ? (<div className="container-xxl flex-grow-1 container-p-y"> Loading ...</div>) : (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="card posts  card-bottom">
        <div className="card-body padd-24" >
          <div className="row" >
            <div className="col-md-3"></div>
            <div className="col-md-3 padd-20 mb-2" >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">Select Type</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="custom-select"
                  label="Select Type"
                  onChange={HandleChangeType}
                  value={type}
                >
                  <MenuItem value={"poster"}>Poster</MenuItem>
                  <MenuItem value={"reel"}>Reel</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3 padd-20 mb-2" >
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">Select Model</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="custom-select"
                  label="Select Type"
                  onChange={HandleChangeModel}
                  value={model}
                >
                   <MenuItem value={"all"}>All</MenuItem>
                   <MenuItem value={"just listed"}>Just Listed</MenuItem>
                   <MenuItem value={"just sold"}>Just Sold</MenuItem>
                  <MenuItem value={"testimonial"}>Testimonial</MenuItem>
                  <MenuItem value={"team"}>Team</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3 padd-20 mb-2">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">Select Theme</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  className="custom-select"
                  label="Select Theme"
                  value={theme}
                  onChange={HandleChangeTheme}
                >
                  <MenuItem value={"all"} key={"all"}>All</MenuItem>
                  {listColors && listColors.map((color: any, index: any) => {
                    return <MenuItem value={color} key={index}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            width: '20px',
                            height: '20px',
                            backgroundColor: color,
                            marginRight: '10px',
                            border: '1px solid #000',
                          }}
                        ></div>
                        {color}
                      </div>
                    </MenuItem>
                  })}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            {listPosts && listPosts.map((post: any, index: any) => {
              return <div className="col-md-4 mb-3" key={index} >
                <div className="card-post">
                  <img src={post.cover} className="img-post" onClick={() => GeneratePost(post)} />
                  <button type="button" className="btn generate mt-3" onClick={() => GeneratePost(post)}>Use</button>
                </div>
              </div>
            })}
          </div>
          {dataLenght == 0 && isSpinner == false && <h3 className="empty mt-4"> No {type=="poster" ? "Posters" : "Reels"}</h3>}

          {isSpinner == true && <div className="row justify-content-center">
            <div className="col-auto">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          }
          {isSpinner == false && <Pagination count={page} variant="outlined" shape="rounded" style={{ marginTop: "25px" }} onChange={handleChangePagination} />}
        </div>
      </div>
    </div>
  )
}
export default ListPost;
import React, { useRef, useEffect, useState } from "react";
import "../Post/Poste.css";
import { PostObj } from "./Post";

const PostTest: React.FunctionComponent<{}> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [postValue, setPostValue] = useState<PostObj>({
    "placeholders": [
        {
            "type": "rectangle",
            "x": 0,
            "y": 0,
            "width": 1080,
            "height": 1080,
            "backgroundColor": "#B6C7AA"
        },
        {
            "type": "rectangle",
            "x": 238,
            "y": 108,
            "width": 604,
            "height": 818,
            "backgroundColor": "#FFFFFF"
        },
        {
            "type": "rectangle",
            "x": 238,
            "y": 926,
            "width": 604,
            "height": 46,
            "backgroundColor": "#A0937D"
        },
        {
            "type": "circle",
            "x": 421,
            "y": 193,
            "width": 239,
            "height": 240,
            "circleColor": "#A0937D"
        }
    ],
    "placeholdersImage": [
        {
            "type": "circle",
            "x": 540,
            "y": 313,
            "radius": 112,
            "backgroundColor": "",
            "src": "https://www.api-v1.coralytics.com/api/images/posters-poster16662974.jpg"
        },
        {
            "type": "rectangle",
            "x": 486,
            "y": 517,
            "width": 110,
            "height": 97,
            "backgroundColor": "#FFFFFFF",
            "src": "https://www.api-v1.coralytics.com/api/images/posters-poster12775871.jpg"
        }
    ],
    "placeholdersText": [
        {
            "type": "rectangle",
            "x": 412,
            "y": 799,
            "width": 256,
            "height": 67,
            "backgroundColor": "#A0937D"
        }
    ],
    "textData": [
        {
            "type": "wrapTextCenter",
            "text": "My husband and I moved from Indiana to be near the children and to enjoy the weather in South Carolina.",
            "x": 320,
            "y": 656,
            "maxWidth": 440,
            "lineHeight": 36,
            "font": " 30px Inter",
            "color": "#000000"
        },
        {
            "type": "wrapTextLeft",
            "text": "Jennifer Black",
            "x": 352,
            "y": 500,
            "maxWidth": 390,
            "lineHeight": 36,
            "font": " 90px Corinthia ",
            "color": "#000000"
        },
        {
            "type": "wrapTextLeft",
            "text": "CHECK OUT",
            "x": 452,
            "y": 842,
            "maxWidth": 180,
            "lineHeight": 36,
            "font": " 30px Inter",
            "color": "#FFFFFF"
        }
    ],
    "linesData": [],
    "width": 1080,
    "height": 1080
});

  const handleChangeImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        if (result) {
          setPostValue((prevOptions) => {
            if (
              !prevOptions.placeholdersImage ||
              prevOptions.placeholdersImage.length <= index
            ) {
              return prevOptions;
            }
            return {
              ...prevOptions,
              placeholdersImage: prevOptions.placeholdersImage.map((image, i) =>
                i === index ? { ...image, src: result } : image
              ),
            };
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const getInitialLength = (text: any) => text.length;

  const handleChangeText = (event: any, indexText: any, text: any) => {
    const newText = event.target.value;
    // const initialLength = getInitialLength(postValue.textData[indexText].text);
    console.log(text.length, "text length");
    // if (newText.length <= initialLength)
    setPostValue((prevOptions) => {
      if (!prevOptions.textData || prevOptions.textData.length <= 1) {
        return prevOptions;
      }
      return {
        ...prevOptions,
        textData: prevOptions.textData.map((item, index) =>
          index === indexText ? { ...item, text: newText } : item
        ),
      };
    });
  };

  const drawRectangleWithFittingCircle = (
    ctx: CanvasRenderingContext2D,
    rectX: number,
    rectY: number,
    rectWidth: number,
    rectHeight: number,
    rectColor: string,
    circleColor: string
  ) => {
    // Draw the rectangle
    ctx.fillStyle = rectColor;
    ctx.fillRect(rectX, rectY, rectWidth, rectHeight);

    // Calculate circle dimensions
    const radius = Math.min(rectWidth, rectHeight) / 2;
    const circleX = rectX + rectWidth / 2;
    const circleY = rectY + rectHeight / 2;

    // Draw the circle
    ctx.fillStyle = circleColor;
    ctx.beginPath();
    ctx.arc(circleX, circleY, radius, 0, Math.PI * 2);
    ctx.fill();
  };

  const drawPlaceholders = (
    ctx: CanvasRenderingContext2D,
    placeholders: any
  ) => {
    placeholders?.forEach((placeholder: any) => {
      const {
        x,
        y,
        type,
        width,
        height,
        radius,
        points,
        circleColor,
        backgroundColor,
      } = placeholder;
      ctx.fillStyle = backgroundColor || "rgba(0, 0, 0, 0)"; // Default to white if no color provided
      ctx.strokeStyle = "#000000";

      switch (type) {
        case "rectangle":
          ctx.fillRect(x, y, width ?? 0, height ?? 0);
          break;
        case "circle":
          drawRectangleWithFittingCircle(
            ctx,
            x, // Rectangle X position
            y, // Rectangle Y position
            width, // Rectangle width
            height, // Rectangle height
            backgroundColor, // Rectangle color
            circleColor // Circle color
          );

          break;
        case "polygon":
          if (points) {
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);
            points.forEach((point: any, i: any) => {
              if (i > 0) ctx.lineTo(point.x, point.y);
            });
            ctx.closePath();
            ctx.fill(); // Fill the polygon with color
          }
          break;
      }
    });
  };

  const drawPlaceholdersImage = (
    ctx: CanvasRenderingContext2D,
    placeholdersImage: any
  ) => {
    placeholdersImage?.forEach((placeholder: any) => {
      const { x, y, type, width, height, radius, points, backgroundColor } =
        placeholder;
      ctx.fillStyle = backgroundColor || "#FFFFFF"; // Default to white if no color provided
      ctx.strokeStyle = "#000000";

      switch (type) {
        case "rectangle":
          ctx.fillRect(x, y, width ?? 0, height ?? 0);
          break;
        case "circle":
          if (radius !== undefined) {
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.fill();
          }
          break;
        case "polygon":
          if (points) {
            ctx.beginPath();
            ctx.moveTo(points[0].x, points[0].y);
            points.forEach((point: any, i: any) => {
              if (i > 0) ctx.lineTo(point.x, point.y);
            });
            ctx.closePath();
            ctx.fill(); // Fill the polygon with color
          }
          break;
      }
    });
  };

  const loadImages = (imageUrls: string[]): Promise<HTMLImageElement[]> => {
    return new Promise((resolve, reject) => {
      const images: HTMLImageElement[] = [];
      let loadedCount = 0;

      imageUrls.forEach((url, index) => {
        const img = new Image();
        img.src = url;

        img.onload = () => {
          loadedCount++;
          images[index] = img;
          if (loadedCount === imageUrls.length) {
            resolve(images);
          }
        };

        img.onerror = () => {
          reject("new Error(Failed to load image at ${url})");
        };
      });
    });
  };

  const drawImages = (
    ctx: CanvasRenderingContext2D,
    placeholdersImage: any[]
  ) => {
    // Iterate through placeholders and images
    placeholdersImage.forEach((placeholder, index) => {
      // Use specific image if available, otherwise fallback to a default image
      const imgSrc = placeholder.src;

      // Créer un nouvel objet Image si nécessaire
      let img: HTMLImageElement;

      if (typeof imgSrc === "string") {
        img = new Image();
        img.src = imgSrc;
      } else if (imgSrc instanceof HTMLImageElement) {
        img = imgSrc;
      } else {
        console.warn("Unsupported image source type for placeholder ${index}");
        return;
      }

      // Draw based on placeholder type
      switch (placeholder.type) {
        case "rectangle":
          ctx.drawImage(
            img,
            placeholder.x,
            placeholder.y,
            placeholder.width ?? 0,
            placeholder.height ?? 0
          );
          break;
        case "circle":
          ctx.save();
          ctx.beginPath();
          ctx.arc(
            placeholder.x,
            placeholder.y,
            placeholder.radius ?? 0,
            0,
            2 * Math.PI
          );
          ctx.clip();
          ctx.drawImage(
            img,
            placeholder.x - (placeholder.radius ?? 0),
            placeholder.y - (placeholder.radius ?? 0),
            (placeholder.radius ?? 0) * 2,
            (placeholder.radius ?? 0) * 2
          );
          ctx.restore();
          break;
        case "polygon":
          if (placeholder.points) {
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(placeholder.points[0].x, placeholder.points[0].y);
            placeholder.points.forEach((point: any) =>
              ctx.lineTo(point.x, point.y)
            );
            ctx.closePath();
            ctx.clip();
            // Draw image in the clipped area
            ctx.drawImage(
              img,
              placeholder.points[0].x,
              placeholder.points[0].y,
              (placeholder.points[1]?.x ?? 0) - placeholder.points[0].x,
              (placeholder.points[2]?.y ?? 0) - placeholder.points[0].y
            );
            ctx.restore();
          }
          break;
        default:
          console.warn("Unknown placeholder type: ${placeholder.type}");
      }
    });
  };

  const drawLines = (
    context: CanvasRenderingContext2D,
    lines: {
      startX: number;
      startY: number;
      endX: number;
      endY: number;
      color: string;
    }[],
    lineWidth: number = 1
  ) => {
    lines?.forEach((line) => {
      context.strokeStyle = line.color;
      context.lineWidth = lineWidth;
      context.beginPath();
      context.moveTo(line.startX, line.startY);
      context.lineTo(line.endX, line.endY);
      context.stroke();
    });
  };

  const wrapTextLeft = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
    font: string,
    color: string
  ) => {
    context.fillStyle = color;
    context.font = ` ${font}`;

    let words = text.split(" ");
    let line = "";
    let yOffset = y;

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + " ";
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, yOffset);
        line = words[n] + " ";
        yOffset += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillText(line, x, yOffset);
  };

  const wrapTextCenter = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
    font: string,
    color: string
  ) => {
    context.fillStyle = color;
    context.font = `${font}`;
    let words = text.split(" ");
    let line = "";
    let yOffset = y;

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + " ";
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        context.fillText(
          line.trim(),
          x + (maxWidth - context.measureText(line.trim()).width) / 2,
          yOffset
        );
        line = words[n] + " ";
        yOffset += lineHeight;
      } else {
        line = testLine;
      }
    }

    context.fillText(
      line.trim(),
      x + (maxWidth - context.measureText(line.trim()).width) / 2,
      yOffset
    );
  };

  const wrapTextRight = (
    context: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
    font: string,
    color: string
  ) => {
    context.fillStyle = color;
    context.font = ` ${font}`;

    let words = text.split(" ");
    let line = "";
    let yOffset = y;

    for (let n = 0; n < words.length; n++) {
      let testLine = line + words[n] + " ";
      let metrics = context.measureText(testLine);
      let testWidth = metrics.width;

      if (testWidth > maxWidth && n > 0) {
        // Draw the text aligned to the right
        context.fillText(
          line.trim(),
          x - context.measureText(line.trim()).width,
          yOffset
        );
        line = words[n] + " ";
        yOffset += lineHeight;
      } else {
        line = testLine;
      }
    }

    // Draw the last line aligned to the right
    context.fillText(
      line.trim(),
      x - context.measureText(line.trim()).width,
      yOffset
    );
  };

  useEffect(() => {
    if (canvasRef.current) {
      const imageUrls: any =
        postValue?.placeholdersImage &&
        postValue?.placeholdersImage.map((placeholder: any) => placeholder.src);
      loadImages(imageUrls)
        .then((images) => {
          const canvas = canvasRef.current;
          if (!canvas) return; // Ensure canvas is not null
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "#FFFFFF"; // Background color
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.fillStyle = "#FFFFFF";
            drawPlaceholders(ctx, postValue?.placeholders);
            drawPlaceholdersImage(ctx, postValue?.placeholdersImage);
            drawImages(ctx, postValue?.placeholdersImage || []);
            drawPlaceholders(ctx, postValue?.placeholdersText);
            postValue?.textData?.forEach((item) => {
              ctx.fillStyle = item.color || "#FFFFFF";
              const text = item.text || "";
              if (item.type === "wrapTextLeft") {
                wrapTextLeft(
                  ctx,
                  item.text || "",
                  item.x ?? 355,
                  item.y ?? 560,
                  item.maxWidth ?? 280,
                  item.lineHeight ?? 80,
                  item.font || "",
                  item.color || "#FFFFFF"
                );
              } else if (item.type === "wrapTextRight") {
                wrapTextRight(
                  ctx,
                  item.text || "",
                  item.x ?? 52,
                  item.y ?? 88,
                  item.maxWidth ?? 406,
                  item.lineHeight ?? 40,
                  item.font || "",
                  item.color || "#FFFFFF"
                );
              } else if (item.type === "wrapTextCenter") {
                wrapTextCenter(
                  ctx,
                  item.text || "",
                  item.x ?? 52,
                  item.y ?? 200,
                  item.maxWidth ?? 406,
                  item.lineHeight ?? 40,
                  item.font || "",
                  item.color || "#FFFFFF"
                );
              }
            });

            // Draw lines
            const linesData: any = postValue?.linesData ?? [];
            drawLines(ctx, linesData, 3);
          }
        })
        .catch((error: any) => {
          console.error("Error loading images:", error);
        });
    }
  }, [postValue]);

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row">
        <div className="col-md-12 desc-property">
          <div className="card ">
            <div className="card-body">
              <div className="row  ">
                <div className="col-md-7 mb-3">
                  <div className="title-card">Generate Post</div>
                </div>
                <div className="col-md-5 mb-3 padd-24 search-generate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card " style={{ marginBottom: "100px" }}>
        <div className="card-body">
          <div className="row">
            <div
              className="col-md-4"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: "30px",
              }}
            >
              {postValue?.textData?.map((text: any, index: any) => {
                return (
                  <input
                    type="text"
                    key={index}
                    className="form-control input mb-3"
                    value={text.text || ""}
                    onChange={(e: any) => {
                      handleChangeText(e, index, text.text);
                    }}
                  />
                );
              })}
              {postValue?.placeholdersImage?.map(
                (image: any, index: number) => {
                  return (
                    <div className="profile-upload mb-3" key={index}>
                      <div>
                        <img src={image.src} alt={`Profile ${index}`} />
                        Image.PNG
                      </div>
                      <label
                        htmlFor={`input-file-${index}`}
                        className="form-label"
                      >
                        <input
                          type="file"
                          id={`input-file-${index}`}
                          style={{ display: "none" }}
                          accept="image/*"
                          onChange={(e) => handleChangeImage(e, index)}
                        />
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.53999 19.5196C4.92999 19.5196 4.35999 19.3096 3.94999 18.9196C3.42999 18.4296 3.17999 17.6896 3.26999 16.8896L3.63999 13.6496C3.70999 13.0396 4.07999 12.2296 4.50999 11.7896L12.72 3.09956C14.77 0.929561 16.91 0.869561 19.08 2.91956C21.25 4.96956 21.31 7.10956 19.26 9.27956L11.05 17.9696C10.63 18.4196 9.84999 18.8396 9.23999 18.9396L6.01999 19.4896C5.84999 19.4996 5.69999 19.5196 5.53999 19.5196ZM15.93 2.90956C15.16 2.90956 14.49 3.38956 13.81 4.10956L5.59999 12.8096C5.39999 13.0196 5.16999 13.5196 5.12999 13.8096L4.75999 17.0496C4.71999 17.3796 4.79999 17.6496 4.97999 17.8196C5.15999 17.9896 5.42999 18.0496 5.75999 17.9996L8.97999 17.4496C9.26999 17.3996 9.74999 17.1396 9.94999 16.9296L18.16 8.23956C19.4 6.91956 19.85 5.69956 18.04 3.99956C17.24 3.22956 16.55 2.90956 15.93 2.90956Z"
                            fill="#282828"
                          />
                          <path
                            d="M17.3404 10.9498C17.3204 10.9498 17.2904 10.9498 17.2704 10.9498C14.1504 10.6398 11.6404 8.26985 11.1604 5.16985C11.1004 4.75985 11.3804 4.37985 11.7904 4.30985C12.2004 4.24985 12.5804 4.52985 12.6504 4.93985C13.0304 7.35985 14.9904 9.21985 17.4304 9.45985C17.8404 9.49985 18.1404 9.86985 18.1004 10.2798C18.0504 10.6598 17.7204 10.9498 17.3404 10.9498Z"
                            fill="#282828"
                          />
                          <path
                            d="M21 22.75H3C2.59 22.75 2.25 22.41 2.25 22C2.25 21.59 2.59 21.25 3 21.25H21C21.41 21.25 21.75 21.59 21.75 22C21.75 22.41 21.41 22.75 21 22.75Z"
                            fill="#282828"
                          />
                        </svg>
                      </label>
                    </div>
                  );
                }
              )}
            </div>
            <div
              className="col-md-8"
              style={{ padding: "20px 30px 20px 20px" }}
            >
              <canvas
                ref={canvasRef}
                width={postValue?.width}
                height={postValue?.height}
                style={{ border: "1px solid black", cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostTest;
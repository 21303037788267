import axios from "axios";

const Url = process.env.REACT_APP_URl;


export const GetPosts = async (obj: any) => {
  const apiUrl = Url + "/getposts";
  const response = await axios.post(apiUrl, obj, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetUploadImages = async (src: any) => {
  const apiUrl = Url + "/uploadimages";
  const response = await axios.post(apiUrl, {
    "image": src,
    "name": "poster1",
    "folder": "posters"
  }, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

export const GetColors = async () => {
  const apiUrl = Url + "/getcolors";
  const response = await axios.get(apiUrl, {
    headers: {
      "Authorization": "Bearer " + localStorage.getItem("usertoken")
    }
  })
  return response.data;
}

import React, { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, ListItemText } from "@mui/material";
import { CreatePortals, DropPortals, GetPortals } from "../../Services/Portals";
import CopyToClipboard from "react-copy-to-clipboard";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import { prefixer } from 'stylis';

const Url = process.env.REACT_APP_URl;

const theme = createTheme({
    direction: 'rtl',
});
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const Portals: React.FunctionComponent<{}> = (props) => {

    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showMessage, setShowMessage] = useState<any>();
    const [platforms, setPlatforms] = useState<any>([{ name: "Coralytics", src: "/assets/images/icons/Coralytics.png" },{ name: "Propcrm", src: "/assets/images/icons/propcrm.png" }, { name: "Propertyfinder", src: "/assets/images/icons/finder.png" }, { name: "Bayut", src: "/assets/images/icons/bayut.png" }, { name: "Dubizzle", src: "/assets/images/icons/dubizzle.png" }]);
    const [selectedPortals, setSelectedPortals] = useState([{ portal: '', url: '' }]);
    const [listPortals, setListPortals] = useState<any>([]);
    const [change, setChange] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    useEffect(() => {
        GetPortals().then((data: any) => {
            setLoading(false)
            if(data.length==0){
                setListPortals([]);
            }else{
                setListPortals(data[0].urls);
            }
        })
    }, [change])


    const handleCopy = () => {
        setIsSuccess(true)
        setIsCopied(true)
        setShowMessage("تم النسخ بنجاح!")
        setTimeout(() => {
            setIsSuccess(false)
        }, 3000);
    };

    const handleChangePortal = (index: any, event: any) => {
        const newSelectedPortals = [...selectedPortals];
        newSelectedPortals[index].portal = event.target.value;
        newSelectedPortals[index].url = `${Url}/xml.feed.publish?platform=${newSelectedPortals[index].portal}&clientid=${localStorage.getItem('id')}`;
        setSelectedPortals(newSelectedPortals);
    };

    const handleChangeURL = (index: any, event: any) => {
        const newSelectedPortals = [...selectedPortals];
        newSelectedPortals[index].url = `${Url}/xml.feed.publish?platform=${newSelectedPortals[index].portal}&clientid=${localStorage.getItem('id')}`;
        setSelectedPortals(newSelectedPortals);
    };

    const addPortal = () => {
        setSelectedPortals([...selectedPortals, { portal: '', url: '' }]);
    };

    const isSaveDisabled = () => {
        return selectedPortals.some((item) => item.portal === '' || item.url === '');
    };

    const openLinkInNewTab = (url: any) => {
        window.open(url, '_blank');
    };
    const Drop = (portal: any) => {
        DropPortals(portal).then((resp: any) => {
            setIsSuccess(true)
            setChange(!change)
            setShowMessage("حذف بنجاح!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("خطأ في حذف البوابات")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 4000)
    }

    const Save = () => {
        CreatePortals(selectedPortals).then((data: any) => {
            setSelectedPortals([{ portal: '', url: '' }]);
            setIsSuccess(true)
            setChange(!change)
            setShowMessage("تم الإنشاء بنجاح!")
        }).catch((error: any) => {
            setIsError(true)
            setShowMessage("حدث خطأ أثناء إنشاء البوابات")
        })
        setTimeout(() => {
            setIsError(false)
            setIsSuccess(false)
        }, 4000)
    }

    return loading ? (<div> تحميل ...</div>) : (
        <>
          {listPortals.length!==0 &&  <div className="row col-users">
                <div className="col-4 mb-2">
                    <label htmlFor="Name-portal" className="form-label">البوابات</label>
                    {listPortals.map((portal2: any, index: any) => (

                        <CacheProvider value={cacheRtl}>
                            <ThemeProvider theme={theme}>
                                <FormControl fullWidth key={index}>
                                    <Select
                                        className="custom-select disabled mb-3"
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Select' }}
                                        value={portal2.portal}
                                        renderValue={(value) => value || 'Select'}
                                    >
                                        <MenuItem value="" disabled>
                                            اختر
                                        </MenuItem>
                                        {platforms.map((platform: any, idx: any) => (
                                            <MenuItem value={platform.name} key={idx}>
                                                <img
                                                    src={platform.src}
                                                    style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }}
                                                    alt={platform.name}
                                                />
                                                <ListItemText primary={platform.name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        </CacheProvider>
                    ))}
                </div>
                <div className="col-4 mb-2">
                    <label htmlFor="Name-urls" className="form-label">الروابط</label>

                    {listPortals.map((portal2: any, index: any) => (
                        <div className="input-group mb-3" key={index}>
                            <input
                                type="text"
                                id={`URL-portal-${index}`}
                                className="form-control"
                                placeholder="http://..."
                                value={portal2.url}
                                readOnly
                            />
                            <div className="btn-group">

                                <button
                                    className="input-group-text"
                                    id={`delete-${index}`}
                                    onClick={() => Drop(portal2.portal)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa fa-trash" aria-hidden="true" style={{ color: "#716BE3", opacity: "1" }}></i>
                                </button>
                                <CopyToClipboard text={portal2.url} onCopy={() => handleCopy}>
                                    {isCopied ? <button className="input-group-text" id={`URL-copy-${index}`}>
                                        <i className="fa-solid fa-check" style={{ color: "#46B49E", opacity: "1" }}></i>
                                    </button>
                                        : <button className="input-group-text" id={`URL-copy-${index}`}>
                                            <i className="fa-solid fa-copy" style={{ color: "#716BE3", opacity: "1" }}></i>
                                        </button>}
                                </CopyToClipboard>

                                <button
                                    className="input-group-text"
                                    id={`URL-open-${index}`}
                                    onClick={() => openLinkInNewTab(portal2.url)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <i className="fa-solid fa-up-right-from-square" style={{ color: "#716BE3", opacity: "1" }}></i>
                                </button>

                            </div>
                        </div>
                    ))}
                </div>
            </div>}

            <div className="row col-users">
                {selectedPortals.map((item, index) => (
                    <div className="row col-users" key={index}>
                        <div className="col-4 mb-2">
                            <label htmlFor={`Name-${index}`} className="form-label">اختر البوابة</label>
                            <CacheProvider value={cacheRtl}>
                                <ThemeProvider theme={theme}>
                                    <FormControl fullWidth>
                                        <Select
                                            className="custom-select"
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Select' }}
                                            value={item.portal}
                                            onChange={(e) => handleChangePortal(index, e)}
                                            renderValue={(value) => value || 'Select'}
                                        >
                                            <MenuItem value="" disabled>
                                                اختر
                                            </MenuItem>
                                            {platforms.map((platform: any, idx: any) => (
                                                <MenuItem value={platform.name} key={idx}>
                                                    <img
                                                        src={platform.src}
                                                        style={{ width: "auto", maxHeight: "20px", marginRight: "10px" }}
                                                        alt={platform.name}
                                                    />
                                                    <ListItemText primary={platform.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                        <div className="col-4 mb-2">
                            <label htmlFor={`URL-${index}`} className="form-label">رابط</label>
                            <input
                                type="text"
                                id={`URL-${index}`}
                                className="form-control mb-3"
                                placeholder="http://..."
                                value={item.url}
                                onChange={(e) => handleChangeURL(index, e)}
                            />
                        </div>
                    </div >
                ))}
                <div className="col-12">
                    <a className="mb-3" id="button-addnew" style={{ width: "100px" }} onClick={addPortal}>
                        <i className="fa-solid fa-plus" style={{ marginLeft: "5px" }}></i> إضافة البوابة
                    </a>
                </div>
            </div >
            <div className="row">
                <div className="col-md-2">
                    <button type="button" className={`btn btn-primary generate marg-top20 ${isSaveDisabled() ? 'disabled' : ''} `} onClick={Save} >حفظ</button>
                </div>
                <div className="col-md-6">
                    {isSuccess && <div className="alert alert-success alert-dismissible marg-top20" role="alert">
                        {showMessage}
                    </div>}
                    {isError && <div className="alert alert-danger alert-dismissible marg-top20" role="alert">
                        {showMessage}
                    </div>}
                </div>
            </div>
        </>
    )
}
export default Portals
